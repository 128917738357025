import { Logger } from 'lib/logger';
import { PhoneNumberType } from 'types/objectTypes';

/* eslint-disable no-param-reassign */
export const capitalizeFirstLetter = <T extends string>(line: string = ''): T => line.charAt(0).toUpperCase() + line.slice(1) as T;

export const replaceContentfulVariables = (
  text: string | undefined,
  replacementEntry: Record<string, string | undefined>,
) => {
  if (!text) {
    return '';
  }

  if (typeof text === 'string') {
    return Object.entries(replacementEntry).reduce((accumulator, [key, value]) => {
      if (!value) {
        return accumulator;
      }

      const regexp = new RegExp(`\\{${key}\\}`, 'g');
      accumulator = accumulator.replace(regexp, value);

      return accumulator;
    }, text);
  }

  return text;
};

export const parseJwt = (token?: string): Record<string, any> => {
  if (!token) {
    return {};
  }

  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64)
      .split('')
      .map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
      .join(''));

    return JSON.parse(jsonPayload);
  } catch (e) {
    Logger.log(e);

    return {};
  }
};

export const isValidEmail = (email: string) => Boolean(email
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  ));

const splice = (original: string, idx: number, rem: number, str: string) => (
  original.slice(0, idx)
    .concat(str)
    .concat(original.slice(idx + Math.abs(rem)))
);

export const formatPhoneInput = ({
  countryCallingCode,
  phoneNumber,
  countryCode,
}: PhoneNumberType) => {
  if (countryCode === 'USA') {
    let formatted: string = phoneNumber;
    formatted = splice(formatted, 0, 0, '(');
    formatted = splice(formatted, 4, 0, ')');
    formatted = splice(formatted, 5, 0, ' ');
    formatted = splice(formatted, 9, 0, '-');

    return `+${countryCallingCode} ${formatted}`;
  }

  return `+${countryCallingCode} ${phoneNumber}`;
};

export const formatPhoneInputFromString = (phoneNumber: string) => {
  const isUSNumber = phoneNumber.substring(0, 2) === '+1' && phoneNumber.length === 12;
  let newString = '';

  if (!isUSNumber) {
    return phoneNumber;
  }

  for (let i = 0; i < phoneNumber.length; i++) {
    const char = phoneNumber.charAt(i);

    if (i === 2) {
      newString += `(${char}`;
    } else if (i === 4) {
      newString += `${char})`;
    } else if (i === 8) {
      newString += `-${char}`;
    } else {
      newString += char;
    }
  }

  return newString;
};

export const camalifyKebab = (text?: string) => {
  if (!text) {
    return '';
  }

  return text
    .split('-')
    .map((word, i) => {
      if (i === 0) {
        return word;
      }

      return capitalizeFirstLetter(word);
    })
    .join('');
};
