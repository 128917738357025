import { IS_CLIENT_SIDE } from 'constants/constants';
import { ShouldCall } from 'lib/decorators';
import { DataLayerEvent } from 'types/objectTypes';

export class GTM {
  @ShouldCall(IS_CLIENT_SIDE)
  public static sendEvent(event: string, rest: Omit<DataLayerEvent, 'event'>) {
    window.dataLayer?.push({ event, ...rest });
  }
}
