import { ViewerDevice } from 'constants/enums';
import { Page, Sidebar } from 'src/__generated__/graphqlTypes';

export const getAsideScreenSpace = (viewerDevice: ViewerDevice, sidebar: Sidebar) => {
  const defaultValue = 20;

  if (viewerDevice === ViewerDevice.DESKTOP && sidebar.screenSpace) {
    return sidebar.screenSpace;
  }

  if (viewerDevice === ViewerDevice.TABLET) {
    if (sidebar.collapse === ViewerDevice.TABLET) {
      return null;
    }

    const tabletSidebarSpace = sidebar.screenSpaceTablet
        || sidebar.screenSpace
        || defaultValue;

    return tabletSidebarSpace;
  }

  if (viewerDevice === ViewerDevice.MOBILE) {
    if (sidebar.collapse === ViewerDevice.MOBILE || sidebar.collapse === ViewerDevice.TABLET) {
      return null;
    }

    const mobileSidebarSpace = sidebar.screenSpaceMobile
        || sidebar.screenSpaceTablet
        || sidebar.screenSpace
        || defaultValue;

    return mobileSidebarSpace;
  }
};

export const shouldSidebarBeActive = (
  viewerDevice: ViewerDevice | null,
  sidebar: Page['sidebar'],
) => {
  if (!viewerDevice || !sidebar) {
    return false;
  }

  return Boolean(getAsideScreenSpace(viewerDevice, sidebar));
};
