import { ServerResponse } from 'http';

import { useEffect } from 'react';
import cookie, { CookieSerializeOptions } from 'cookie';
import jstz from 'jstz';
import { useRouter } from 'next/router';

import {
  USER_TIME_ZONE,
  ENHANCED_PRICING,
  HOMEPAGE_SLUG,
  MINUTE_IN_SECONDS,
} from 'constants/constants';
import { NodeEnv } from 'constants/enums';
import { Logger } from 'lib/logger';

export const getCookieByName = (name: string): string => {
  if (typeof document === 'object') {
    return document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || '';
  }

  return '';
};

export const updateExistingCookie = (
  cookieName: string,
  existingValue: string,
  newValue?: string,
  headerCookie?: string,
): string | undefined => headerCookie?.replace(`${cookieName}=${existingValue}`, `${cookieName}=${newValue}`);

export const addNewCookie = (
  cookieName: string,
  newValue: string,
  headerCookie?: string,
): string => (headerCookie ? `${headerCookie};${cookieName}=${newValue};` : `${cookieName}=${newValue};`);

export const useEnhancedPricing = (slug: string) => {
  const router = useRouter();
  const { enhancedpricing = '' } = router.query;

  useEffect(() => {
    if (slug !== HOMEPAGE_SLUG || process.env.NEXT_PUBLIC_IS_ENHANCED_PRICING_ENABLED === 'false' || !enhancedpricing) {
      return;
    }

    try {
      const currentDate = new Date();
      const expirationDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + 10));
      const options: CookieSerializeOptions = {
        secure: process.env.NODE_ENV === NodeEnv.PROD,
        path: '/',
        expires: expirationDate,
      };
      document.cookie = cookie.serialize(
        ENHANCED_PRICING,
        enhancedpricing as string,
        options,
      );
    } catch (e) {
      Logger.error(e);
    }
  }, [enhancedpricing, slug]);
};

export const useStoreClientTimezone = () => {
  useEffect(() => {
    try {
      const cookieObject = cookie.parse(document.cookie);
      const userTimeZone = cookieObject[USER_TIME_ZONE];
      const lastTimezone = userTimeZone ? JSON.parse(userTimeZone) : {};
      const timezone = jstz.determine();
      const timezoneName = timezone.name();
      const value = { ...lastTimezone, timezone: timezoneName };
      const options: CookieSerializeOptions = {
        secure: process.env.NODE_ENV === NodeEnv.PROD,
        path: '/',
      };
      document.cookie = cookie.serialize(USER_TIME_ZONE, JSON.stringify(value), options);
    } catch (e) {
      Logger.error(e);
    }
  }, []);
};

export const setDefaultCacheBehavior = (minutes: number, resp: ServerResponse) => {
  resp.setHeader(
    'Cache-Control',
    `public, max-age=0, s-maxage=${MINUTE_IN_SECONDS * minutes}`,
  );
};
