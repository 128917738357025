import { AxiosError, AxiosResponse } from 'axios';
import btoa from 'btoa';

import { ViewerDevice } from 'constants/enums';
import { remoteServerAxios, proxyServerAxios } from 'src/api/axios';
import type {
  AuthenticationCredentials,
  ChatTokens,
  RemoteSignInQuery,
  UserCredentials,
  UserType,
} from 'types/objectTypes';

export const signIn = (
  username: string,
  password: string,
  query?: RemoteSignInQuery,
  cookie?: string,
) => remoteServerAxios
  .post('auth/basic', null, {
    headers: {
      Authorization: `Basic ${btoa(`${username}:${password}`)}`,
      ...(cookie ? { Cookie: cookie } : {}),
    },
    params: {
      cid: query?.clientId,
      sl: query?.location,
    },
  })
  .then((resp: AxiosResponse) => resp)
  .catch((e: AxiosError) => {
    throw e.response;
  });

export const signInProxy = (
  { email, password }: UserCredentials,
  device: ViewerDevice,
) => proxyServerAxios
  .post('login', { email, password, location: device })
  .then((resp: AxiosResponse<UserType>) => resp.data)
  .catch((e: AxiosError) => {
    throw e.response;
  });

export const getChatTokens = (authorization?: string) => remoteServerAxios
  .get('auth', { headers: { Authorization: authorization } })
  .then((resp: AxiosResponse) => resp)
  .catch((e: AxiosError) => {
    throw e.response;
  });

export const getChatTokensProxy = (authObject: AuthenticationCredentials) => proxyServerAxios
  .post('auth/tokens', authObject)
  .then((resp: AxiosResponse<ChatTokens>) => resp.data)
  .catch((e: AxiosError) => {
    throw e.response;
  });

export const signOutProxy = () => proxyServerAxios
  .post('logout', null)
  .then((resp: AxiosResponse) => resp.data)
  .catch((e: AxiosError) => {
    throw e.response;
  });

export const signOut = (token: string) => remoteServerAxios
  .post('auth/logout', null, { headers: {
    Authorization: `Token ${token}`,
  } })
  .then((resp: AxiosResponse) => resp)
  .catch((e: AxiosError) => {
    throw e.response;
  });

export const checkSignInAttempt = (email: string) => remoteServerAxios
  .post('CheckSigninAttempt', { Email: email })
  .then((resp: AxiosResponse) => resp)
  .catch((e: AxiosError) => {
    throw e.response;
  });

export const sendRecaptchaResult = (email: string, key: string | null) => remoteServerAxios
  .post('GooglereCAPTCHA', { Email: email, Responce: key })
  .then((resp: AxiosResponse) => resp)
  .catch((e: AxiosError) => {
    throw e.response;
  });

export const verifyUser = () => proxyServerAxios
  .get('/api/user')
  .then((resp) => resp.data)
  .catch((e: AxiosError) => {
    throw e.response;
  });

export const requestLogout = () => proxyServerAxios
  .get('/api/logout')
  .then((resp) => resp.data)
  .catch((e: AxiosError) => {
    throw e.response;
  });
