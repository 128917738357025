import { NextApiRequest, NextApiResponse } from 'next';
import cookie, { CookieSerializeOptions } from 'cookie';

import { SESSION_COOKIE_NAME, ONE_HUNDRED_DAYS } from 'constants/constants';
import { ServerSideRenderingContext } from 'types/objectTypes';
import { CustomerType } from 'constants/enums';
import { applySetCookieHeader } from 'lib/server.service';
import { calculateCookiesDomain } from 'src/shared/lib/cookie';

type CookieData = {
  cookieName: string,
  ttl?: number,
  cookieOptions: CookieSerializeOptions,
}

const ADDITIONAL_YEARS = 10;

class SessionStorage {
  req: NextIronRequest;

  resp: NextApiResponse;

  options: CookieData;

  constructor(req: NextIronRequest, resp: NextApiResponse, options: CookieData) {
    this.req = req;
    this.resp = resp;
    this.options = options;
  }

  getSession() {
    const cookies = (Object.keys(this.req.cookies).length)
      ? this.req.cookies
      : cookie.parse(this.req.headers.cookie || '');
    const sessionCookie = cookies[this.options.cookieName];
    try {
      const sessionObject = JSON.parse(sessionCookie);

      return sessionObject;
    } catch (e) {
      return null;
    }
  }

  setSession(value: any) {
    const serializedObject = JSON.stringify(value);
    const serializedCookie = cookie.serialize(
      this.options.cookieName,
      serializedObject,
      this.options.cookieOptions,
    );
    applySetCookieHeader(this.resp, serializedCookie);
  }

  stopSession() {
    const cookies = cookie.parse(this.req.headers.cookie || '');
    const sessionCookie = cookies[this.options.cookieName];
    const sessionObject = sessionCookie && JSON.parse(sessionCookie);

    if (!sessionObject) {
      return;
    }

    const stringifySessionObject = JSON.stringify(sessionObject);
    const cookieValue = cookie.serialize(
      this.options.cookieName,
      stringifySessionObject,
      { ...this.options.cookieOptions, maxAge: 0 },
    );

    applySetCookieHeader(this.resp, cookieValue);
  }
}

export type NextIronRequest = NextApiRequest & { session: SessionStorage};

const applySession = (req: NextIronRequest, resp: NextApiResponse, options: CookieData) => {
  req.session = new SessionStorage(req, resp, options);
};

export const sessionWrapper = (
  withSessionHandler:
  ((req: NextIronRequest, resp: NextApiResponse) => void)
  | ((context: ServerSideRenderingContext) => Promise<any>),
  cookieData: CookieData = {
    cookieName: SESSION_COOKIE_NAME,
    ttl: ONE_HUNDRED_DAYS,
    cookieOptions: { },
  },
) => async function withIronSessionHandler(...args: any) {
  const handlerType = args[0] && args[1] ? 'api' : 'ssr';
  const req: NextIronRequest = handlerType === 'api' ? args[0] : args[0].req;
  const res: NextApiResponse = handlerType === 'api' ? args[1] : args[0].res;
  let { domain } = cookieData.cookieOptions;

  if (req.headers.origin) {
    domain = calculateCookiesDomain(req.headers.origin);
  }

  const cookieDataWithDomain = {
    ...cookieData,
    cookieOptions: {
      ...cookieData.cookieOptions,
      domain,
    },
  };
  applySession(req, res, cookieDataWithDomain);

  // @ts-ignore
  return withSessionHandler(...args);
};

const sessionCookieOptions: any = {
  httpOnly: false,
  secure: process.env.NODE_ENV === 'production',
  sameSite: 'lax',
  path: '/',
  expires: new Date(new Date().setFullYear(new Date().getFullYear() + ADDITIONAL_YEARS)),
};

const withSession = (handler: ((req: NextIronRequest, resp: NextApiResponse) => void)
| ((context: ServerSideRenderingContext) => Promise<any>)) => sessionWrapper(
  handler,
  {
    cookieName: SESSION_COOKIE_NAME,
    cookieOptions: sessionCookieOptions,
  },
);

export const withSessionSsr = (
  context: ServerSideRenderingContext,
) => {
  const cookieData = {
    cookieName: SESSION_COOKIE_NAME,
    cookieOptions: sessionCookieOptions,
  };

  const { req, res } = context;
  let { domain } = cookieData.cookieOptions;

  if (req.headers.origin) {
    if (process.env.NODE_ENV === 'production'
          && req.headers.origin?.includes(process.env.CP_DOMAIN || '')) {
      domain = process.env.CP_DOMAIN;
    } else {
      domain = undefined;
    }
  }

  const cookieDataWithDomain = {
    ...cookieData,
    cookieOptions: {
      ...cookieData.cookieOptions,
      domain,
    },
  };
  // @ts-ignore
  applySession(req, res, cookieDataWithDomain);
};

export { sessionCookieOptions };

export default withSession;

export const calculateCustomerType = (sessionStorage?: SessionStorage) => {
  if (!sessionStorage) {
    return CustomerType.NC;
  }

  const session = sessionStorage.getSession();

  if (!session) {
    return CustomerType.NC;
  }

  return CustomerType.EC;
};
