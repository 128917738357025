/* eslint-disable class-methods-use-this */
import { AnalyticsBrowser } from '@segment/analytics-next';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useEffect, RefObject } from 'react';

import { CustomerType, OAPlatform } from 'constants/enums';
import { Store } from 'app-redux/types/storeTypes';
import { IS_CLIENT_SIDE } from 'constants/constants';
import { ShouldCall } from 'lib/decorators';
import { Logger } from 'lib/logger';
import { Link } from 'src/__generated__/graphqlTypes';
import { SegmentTrackingHookExtraData, SegmentTrackingData } from 'types/objectTypes';

const shouldFireEvents = process.env.NEXT_PUBLIC_IS_SEGMENT_ENABLED === 'true'
  && IS_CLIENT_SIDE
  && Boolean(window.analytics);

const analytics = IS_CLIENT_SIDE ? window.analytics as AnalyticsBrowser : undefined;

export class Segment {
  @ShouldCall(shouldFireEvents)
  public static track(...parameters: Parameters<AnalyticsBrowser['track']>) {
    const func = analytics?.track(...parameters);

    if (typeof func?.catch === 'function') {
      func.catch((err) => Logger.error(err));
    }
  }

  @ShouldCall(shouldFireEvents)
  public static trackLink(...parameters: Parameters<AnalyticsBrowser['trackLink']>) {
    const func = analytics?.trackLink(...parameters);

    if (typeof func?.catch === 'function') {
      func.catch((err) => Logger.error(err));
    }
  }

  @ShouldCall(shouldFireEvents)
  public static identify(...parameters: Parameters<AnalyticsBrowser['identify']>) {
    const func = analytics?.identify(...parameters);

    if (typeof func?.catch === 'function') {
      func.catch((err) => Logger.error(err));
    }
  }

  @ShouldCall(shouldFireEvents)
  public static reset() {
    analytics?.reset();
  }

  public static page(...parameters: Parameters<AnalyticsBrowser['page']>) {
    const func = analytics?.page(...parameters);

    if (typeof func?.catch === 'function') {
      func.catch((err) => Logger.error(err));
    }
  }
}

export const useFetchSegmentData = (
  link: Link | undefined | null,
  { postProcessor, defaultData }: SegmentTrackingHookExtraData = {},
) => {
  const router = useRouter();
  const userDetails = useSelector((store: Store) => store.server.auth.user);
  const oaPlatform = useSelector((store: Store) => store.server.app.oaPlatform);
  const customerType = !userDetails ? CustomerType.NC : CustomerType.EC;
  const defaultSegmentData = {
    cta_content: link?.title?.toLowerCase(),
    cta_destination: `${process.env.NEXT_PUBLIC_BASE_SERVER_URL}${link?.src}`,
    cta_position: 'banner',
    cta_type: 'button',
    url: `${process.env.NEXT_PUBLIC_BASE_SERVER_URL}${router.asPath}`,
    user_type: customerType,
    platform: (oaPlatform === OAPlatform.DESKTOP)
      ? OAPlatform.DESKTOP
      : 'mobile web',
    ...defaultData,
  };
  const finalSegmentData = { ...defaultSegmentData, ...link?.segmentData };

  if (postProcessor) {
    return postProcessor(finalSegmentData);
  }

  return finalSegmentData;
};

export const useRaiseTrackLinkEvent = (
  ref: RefObject<any>,
  segmentData?: SegmentTrackingData,
  name: string = 'cta_clicked',
) => {
  useEffect(() => {
    if (!segmentData || !Object.keys(segmentData).length) {
      return;
    }

    Segment.trackLink(ref.current, name, segmentData);
  }, [segmentData]);
};
