import * as types from 'app-redux/types/actionTypes';
import { UserType } from 'types/objectTypes';

export const setAuthenticatedState = (isAuthenticated: boolean) => ({
  type: types.SET_AUTHENTICATED_STATE,
  payload: isAuthenticated,
});

export const setUser = (user: UserType | null = null) => ({
  type: types.SET_USER,
  payload: user,
});
