import { Parser } from 'bowser';

import * as types from 'app-redux/types/actionTypes';
import { OAPlatform, ViewerDevice } from 'constants/enums';
import { BootState } from 'common-chat-components/enums';

export const setScssVariables = (variables: any) => ({
  type: types.SET_SCSS_VARIABLES,
  payload: variables,
});

export const setMobileViewWidthState = (flag: boolean) => ({
  type: types.SET_MOBILE_VIEW_WIDTH_STATE,
  payload: flag,
});

export const setCtmChatLoadingState = (flag: boolean) => ({
  type: types.SET_CTM_CHAT_LOADING_STATE,
  payload: flag,
});

export const setCtmNumber = (number: string) => ({
  type: types.SET_CTM_NUMBER,
  payload: number,
});

export const setViewerDevice = (viewerDevice: ViewerDevice | null = ViewerDevice.DESKTOP) => ({
  type: types.SET_VIEWER_DEVICE,
  payload: viewerDevice,
});

export const setOAPlatform = (platform: OAPlatform) => ({
  type: types.SET_OA_PLATFORM,
  payload: platform,
});

export const setServerCookies = (cookies: Record<string, any>) => ({
  type: types.SET_SERVER_COOKIES,
  payload: cookies,
});

export const setMobileHeaderNavActivityState = (flag: boolean) => ({
  type: types.SET_MOBILE_HEADER_NAV_ACTIVITY_STATE,
  payload: flag,
});

export const setUserAgent = (userAgent: Parser.ParsedResult) => ({
  type: types.SET_USER_AGENT,
  payload: userAgent,
});

export const setDesktopRequestedState = (flag: boolean) => ({
  type: types.SET_DESKTOP_REQUESTED_STATE,
  payload: flag,
});

export const setFirebaseLoadedState = (flag: boolean) => ({
  type: types.SET_FIREBASE_LOADED_STATE,
  payload: flag,
});

export const setFirebaseRequiredState = (flag: boolean) => ({
  type: types.SET_FIREBASE_REQUIRED_STATE,
  payload: flag,
});

export const setLoadingState = (flag: boolean) => ({
  type: types.SET_LOADING_STATE,
  payload: flag,
});

export const setClientWidth = (width: number) => ({
  type: types.SET_CLIENT_WIDTH,
  payload: width,
});

export const setBootStatus = (bootStatus: BootState) => ({
  type: types.SET_BOOT_STATUS,
  payload: bootStatus,
});

export const setSidebarActivity = (isSidebarActive: boolean) => ({
  type: types.SET_SIDEBAR_ACTIVITY_STATE,
  payload: isSidebarActive,
});
