import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import { IS_CLIENT_SIDE } from 'constants/constants';
import { logRequestMetadata } from 'lib/logger';
import { invalidateSessionRedirect as invalidateSessionAndRedirect } from 'src/shared/lib/auth';

export const remoteServerAxios: AxiosInstance = axios
  .create({ baseURL: process.env.BASE_SERVER_URL || process.env.NEXT_PUBLIC_BASE_SERVER_URL });

const getResponseErrorInterceptorCb = (isMobile: boolean = false) => (error: any) => {
  const { config, response } = error || {};

  logRequestMetadata(config, isMobile, error);

  if (response.statusCode === 401) {
    const is401AtCP = IS_CLIENT_SIDE
      && window.location.origin.includes(process.env.NEXT_PUBLIC_CP_DOMAIN || '')
      && process.env.NODE_ENV === 'production';

    if (is401AtCP) {
      invalidateSessionAndRedirect();
    }
  }

  return Promise.reject(error);
};
const getResponseSuccessInterceptorCb = (isMobile: boolean = false) => (
  response: AxiosResponse<any>,
) => {
  const { config } = response;
  logRequestMetadata(config, isMobile, response.status);

  return response;
};

remoteServerAxios.defaults.params = {};
remoteServerAxios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const mutableConfig = config;

    if (mutableConfig.params) {
      mutableConfig.params.ts = `ts_${new Date().getTime()}`;
    }

    // @ts-ignore
    mutableConfig.metadata = { startTime: new Date().getTime() };

    return mutableConfig;
  },
  getResponseErrorInterceptorCb(true),
);

remoteServerAxios.interceptors.response.use(
  getResponseSuccessInterceptorCb(true),
  getResponseErrorInterceptorCb(true),
);

export const proxyServerAxios: AxiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_SUB_PATH
    ? `${process.env.NEXT_PUBLIC_SUB_PATH}/api`
    : '/api',
});

proxyServerAxios.interceptors.response.use(
  (response: AxiosResponse<any>) => response,
  (error: AxiosError) => {
    const { response } = error;

    if (response?.status === 401) {
      const is401AtCP = IS_CLIENT_SIDE
        && window.location.origin.includes(process.env.NEXT_PUBLIC_CP_DOMAIN || '')
        && process.env.NODE_ENV === 'production';

      if (is401AtCP) {
        invalidateSessionAndRedirect();
      }
    }

    return Promise.reject(error);
  },
);

export const constructError = (e: AxiosError) => (
  e.response
    ? {
      status: e.response.status,
      data: e.response.data,
    }
    : {
      status: 0,
      data: {},
    });
