import { AxiosError } from 'axios';

import { remoteServerAxios, proxyServerAxios } from 'src/api/axios';
import { FreeHoroscopeInputsReducerType, HoroscopesRequestQueryParams } from 'types/objectTypes';

export const getHoroscope = (params: any) => remoteServerAxios
  .get('chat/mhoroscope', { params, baseURL: `https://www${process.env.CP_DOMAIN}` })
  .then((resp) => resp)
  .catch((e: AxiosError) => { throw e.response; });

export const getHoroscopeProxy = (params: HoroscopesRequestQueryParams) => proxyServerAxios
  .get('horoscopes', { params })
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response; });

export const subscribeToHoroscopes = (params: any) => remoteServerAxios
  .post('mhoroscope/signup', { ...params })
  .then((resp) => resp)
  .catch((e: AxiosError) => { throw e.response; });

export const subscribeToHoroscopesProxy = (
  { email, name, birthDate }: FreeHoroscopeInputsReducerType,
) => proxyServerAxios
  .post('horoscopes/subscribe', { email, name, birthDate: JSON.stringify(birthDate) })
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response; });
